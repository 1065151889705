import React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import SEO from '../components/seo';
import {
  H2,
  H3,
  H4,
  P2,
  Tile,
  B2,
  StyledLink,
} from '../components/page-elements';
import Container from '../components/container';
import Layout from '../components/layout';

import TickIcon from '../images/svg/tick.inline.svg';

const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing[20]};
  text-align: center;

  @media (min-width: 48em) {
    margin-top: ${({ theme }) => theme.spacing[48]};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const ImageContainer = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.spacing[20]};
  position: relative;
  display: none;

  @media (min-width: 48em) {
    display: unset;
  }
`;

const Image = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-size: cover;
`;

const DetailsContainer = styled.div`
  flex: 1;
  text-align: left;
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  &:last-child {
    margin-bottom: 0;
  }
`;

const BenefitIcon = styled(TickIcon)`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing[2]};
`;

const BenefitText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
`;

const CTATile = styled(Tile)`
  margin: ${({ theme }) => theme.spacing[32]} auto;
  width: 95%;
  background-color: ${({ theme }) => theme.colors.secondary[800]};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23f1f7f8' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  padding: ${({ theme }) => theme.spacing[10]}
    ${({ theme }) => theme.spacing[8]};
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 48em) {
    align-items: center;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing[10]}
      ${({ theme }) => theme.spacing[12]};
  }

  @media (min-width: 64em) {
    width: 80%;
  }
`;

const CTATitle = styled(H3)`
  margin: 0;
  margin-bottom: 3em;
  max-width: 400px;
  text-align: left;

  @media (min-width: 37.5em) {
    margin-bottom: 2em;
    padding-right: 2em;
  }
  @media (min-width: 48em) {
    margin-bottom: 0;
  }
`;

const CTAButton = styled(StyledLink)`
  align-self: flex-end;
  width: 100%;

  > button {
    width: 100%;
  }

  @media (min-width: 27em) {
    width: unset;
  }

  @media (min-width: 48em) {
    align-self: unset;
  }
`;
// const BenefitTitle = styled;

const MembershipPage = ({ data }) => (
  <>
    <Layout>
      <SEO title="Membership" />
      <StyledContainer>
        <H2>Membership</H2>
        <H3>Why join the AIMRO network?</H3>
        <Row>
          <ImageContainer>
            <Image fluid={data.sanityTemp.image2.asset.fluid} />
          </ImageContainer>
          <DetailsContainer>
            <BenefitContainer>
              <BenefitIcon />
              <BenefitText>
                <H4>Promotion</H4>
                <P2>
                  AIMRO protects the research & insight industry in Ireland,
                  promoting its quality and skills, while lobbying on behalf of
                  the industry to relevant authorities.
                </P2>
              </BenefitText>
            </BenefitContainer>
            <BenefitContainer>
              <BenefitIcon />
              <BenefitText>
                <H4>Accreditation</H4>
                <P2>
                  AIMRO advances and safeguards the interests of the industry
                  and to procure and maintain high professional standards. The
                  quality credentials that member accreditation provides is
                  highly sought after by purchasers in Ireland and abroad.
                </P2>
              </BenefitText>
            </BenefitContainer>
            <BenefitContainer>
              <BenefitIcon />
              <BenefitText>
                <H4>Networking</H4>
                <P2>
                  AIMRO provides a central hub for our members, to network and
                  support the industry, creating a culture for market
                  researchers to be better equipped and prepared to succeed in
                  today’s business world.
                </P2>
              </BenefitText>
            </BenefitContainer>
          </DetailsContainer>
        </Row>
        <CTATile>
          <CTATitle>
            Become a member of the highest standard research organisation in the
            Republic of Ireland.
          </CTATitle>
          <CTAButton to="/contact">
            <B2>Get in touch</B2>
          </CTAButton>
        </CTATile>
      </StyledContainer>
    </Layout>
  </>
);

export const membershipPageQuery = graphql`
  {
    sanityTemp {
      image {
        asset {
          fluid {
            src
          }
        }
      }
      image4 {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      image2 {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      image3 {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default MembershipPage;
