import styled, { css } from 'styled-components';

const P2Styles = css`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.colors.secondary[300]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;

export const P2 = styled.p`
  ${P2Styles}
`;

// For pre-formatted text (i.e: with paragraph/line breaks)
export const P2Pre = styled.pre`
  ${P2Styles}
  white-space: pre-wrap;
`;
